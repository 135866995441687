import React from 'react';

export default function Features() {
  return (
    <section className="ui-section-feature">
      <div className="ui-layout-container">
        <h2 className="h2">Nice Features</h2>
        <p className="p ui-text-intro">ChatGPT Prompts Cool features</p>
        <div className="ui-section-feature__layout ui-layout-grid ui-layout-grid-3">
          <div className="ui-component-card ui-layout-column-4">
            <img
              src="https://res.cloudinary.com/uisual/image/upload/assets/graphics/chat.png"
              loading="lazy"
              alt="#"
              className="img ui-component-card--image"
            />
            <h4 className="h4 ui-component-card--title">Contacts Sync</h4>
            <p className="p">Syncing of contacts between a desktop and a phonebook.</p>
          </div>
          <div className="ui-component-card ui-layout-column-4">
            <img
              src="https://res.cloudinary.com/uisual/image/upload/assets/graphics/chat.png"
              loading="lazy"
              alt="#"
              className="img ui-component-card--image"
            />
            <h4 className="h4 ui-component-card--title">Save Contacts</h4>
            <p className="p">Directly save contacts from any webpage to your phone.</p>
          </div>
          <div className="ui-component-card ui-layout-column-4">
            <img
              src="https://res.cloudinary.com/uisual/image/upload/assets/graphics/chat.png"
              loading="lazy"
              alt="#"
              className="img ui-component-card--image"
            />
            <h4 className="h4 ui-component-card--title">Smart Filter.</h4>
            <p className="p">Filter phone numbers and email from a selected text.</p>
          </div>
        </div>
      </div>
    </section>
  );
}
