import constants from '../constants';

export const generateGuid = () => {
  let result;
  let i;
  let j;
  result = '';
  // eslint-disable-next-line no-plusplus
  for (j = 0; j < 32; j++) {
    if (j === 8 || j === 12 || j === 16 || j === 20) result += '-';
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    result += i;
  }
  return result;
};
export const http = async (method, url, data) => {
  const response = await fetch(url, {
    method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.status === 200 ? response.json() : http(method, url, data);
};
export const loadImage = (src) => new Promise((resolve, reject) => {
  const img = new Image();
  img.addEventListener('load', () => {
    const canvas = document.createElement('canvas');
    canvas.height = img.naturalHeight;
    canvas.width = img.naturalWidth;
    canvas.getContext('2d').drawImage(img, 0, 0);
    const b64data = canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, '');
    resolve(b64data);
  });
  img.addEventListener('error', (err) => reject(err));
  img.src = src;
});
/**
 *@method  urlWithoutQueryParameters
 * @param urlString string
 * @returns string url without get query parameters
 */
export const urlWithoutQueryParameters = (urlString) => {
  const u = new URL(urlString);
  return u.origin + u.pathname;
};

/**
 *@method  extractHostname
 * @param url string
 * @returns string hostname
 */
export const extractHostname = (url) => {
  let hostname;
  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    // eslint-disable-next-line prefer-destructuring
    hostname = url.split('/')[2];
  } else {
    // eslint-disable-next-line prefer-destructuring
    hostname = url.split('/')[0];
  }
  // find & remove port number
  // eslint-disable-next-line prefer-destructuring
  hostname = hostname.split(':')[0];
  // find & remove "?"
  // eslint-disable-next-line prefer-destructuring
  hostname = hostname.split('?')[0];

  return hostname;
};
export const copyText = (text) => {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};
/**
 * Detect browser language id
 * */
export const getBrowserLangId = () => {
  let language;
  if (window.navigator.languages) {
    // eslint-disable-next-line prefer-destructuring
    language = window.navigator.languages[0];
  } else {
    language = window.navigator.userLanguage || window.navigator.language;
  }
  return language;
};
/**
 * Get browser Locale
 * */
export const getBrowserLocale = () => {
  const langId = getBrowserLangId();
  const locale = langId.split('-');
  return locale[0];
};
/**
 * return browser name. if it's google chrome than return false.
 * */
export const isBadBrowser =
    // eslint-disable-next-line no-nested-ternary
    window.navigator.userAgent.indexOf('Edg') > -1
      ? 'Microsoft Edge'
      : // eslint-disable-next-line no-nested-ternary
      window.navigator.userAgent.indexOf('Firefox') > -1
        ? 'Firefox'
        : // eslint-disable-next-line no-nested-ternary,no-void
        void 0 !== window.navigator.brave
          ? 'Brave'
          : // eslint-disable-next-line no-nested-ternary
          window.navigator.userAgent.match(/Yowser|YaBrowser\//)
            ? 'Yandex'
            : // eslint-disable-next-line no-void
            void 0 !== window.safari
              ? 'Safari'
              : !!(function () {
                for (let a = 0; a < navigator.plugins.length; a += 1) if (navigator.plugins[a].name != null && navigator.plugins[a].name.indexOf('Chromium') !== -1) return !0;
                return !1;
              }()) && 'Chromium';
export const isGoogleChrome = isBadBrowser === false;
export const isBrave = isBadBrowser === 'Brave';
export const isFirefox = isBadBrowser === 'Firefox';
export const isMicrosoftEdge = isBadBrowser === 'Microsoft Edge';
export const isChromium = isBadBrowser === 'Chromium';
export const isOpera = !!navigator.userAgent.match(/Opera|OPR\//);
export const isYandex = isBadBrowser === 'Yandex';
/** communicate to ext-v2 using its id provided by ext-v2 store */
export const sendMessageToImageText = (path, params, callback) => {
  let extensionId = '';
  if (isOpera || isYandex) {
    extensionId = constants.extension.image_to_text_ocr.opera.id;
  } else if (isGoogleChrome) {
    extensionId = constants.extension.image_to_text_ocr.chrome.id;
  } else if (isMicrosoftEdge) {
    extensionId = constants.extension.image_to_text_ocr.edge.id;
  } else {
    extensionId = constants.extension.image_to_text_ocr.chrome.id;
  }
  // eslint-disable-next-line no-undef
  chrome?.runtime?.sendMessage(extensionId, { path, ...params }, callback);
};
/** communicate to ext-v2 using its id provided by ext-v2 store */
export const sendMessageToDictionaryExtension = (path, params, callback) => {
  let extensionId = '';
  if (isOpera || isYandex) {
    extensionId = constants.extension.dictionary.opera.id;
  } else if (isGoogleChrome) {
    extensionId = constants.extension.dictionary.chrome.id;
  } else if (isMicrosoftEdge) {
    extensionId = constants.extension.dictionary.edge.id;
  } else {
    extensionId = constants.extension.dictionary.chrome.id;
  }
  // eslint-disable-next-line no-undef
  chrome?.runtime?.sendMessage(extensionId, { path, ...params }, callback);
};
/** communicate to ext-v2 using its id provided by ext-v2 store */
export const sendMessageToImageEditorWeb = (path, params, callback) => {
  let extensionId = '';
  if (isOpera || isYandex) {
    extensionId = constants.extension.image_editor_web.opera.id;
  } else if (isGoogleChrome) {
    extensionId = constants.extension.image_editor_web.chrome.id;
  } else if (isMicrosoftEdge) {
    extensionId = constants.extension.image_editor_web.edge.id;
  } else {
    extensionId = constants.extension.image_editor_web.chrome.id;
  }
  // eslint-disable-next-line no-undef
  chrome?.runtime?.sendMessage(extensionId, { path, ...params }, callback);
};
/** communicate to ext-v2 using its id provided by ext-v2 store */
export const sendMessageToObjectFinderAI = (path, params, callback) => {
  let extensionId = '';
  if (isOpera || isYandex) {
    extensionId = constants.extension.object_finder_ai.opera.id;
  } else if (isGoogleChrome) {
    extensionId = constants.extension.object_finder_ai.chrome.id;
  } else if (isMicrosoftEdge) {
    extensionId = constants.extension.object_finder_ai.edge.id;
  } else {
    extensionId = constants.extension.object_finder_ai.chrome.id;
  }
  // eslint-disable-next-line no-undef
  chrome?.runtime?.sendMessage(extensionId, { path, ...params }, callback);
};

/** Random rgba color generator */
export function randomRGBA() {
  const o = Math.round;
  const r = Math.random;
  const s = 255;
  return `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},${r().toFixed(1)})`;
}

export function truncateString(str, num) {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
}

export function logOCR(text, isFile = false) {
  const { baseUrl } = constants.webhook;
  const endpoint = isFile
    ? `${baseUrl}/ocr-premium?isFile=true&text=${encodeURIComponent(text)}`
    : `${baseUrl}/ocr-premium?text=${encodeURIComponent(text)}`;
  fetch(endpoint, {
    method: 'POST',
  }).catch((e) => {
    console.log({ e });
  });
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const serverEndpoint = process.env.NODE_ENV === 'production' ? constants.server.endpoint.prod : constants.server.endpoint.local;

export function logST(text) {
  const { baseUrl } = constants.webhook;
  const endpoint = `${baseUrl}/st?isFile=false&text=${encodeURIComponent(text)}`;
  fetch(endpoint, {
    method: 'POST',
  }).catch((e) => {
    console.log({ e });
  });
}

export default {
  generateGuid,
  http,
  loadImage,
  urlWithoutQueryParameters,
  extractHostname,
  copyText,
  sendMessageToImageText,
  sendMessageToImageEditorWeb,
  sendMessageToObjectFinderAI,
  getBrowserLocale,
  getBrowserLangId,
  isGoogleChrome,
  isBrave,
  isFirefox,
  isMicrosoftEdge,
  isChromium,
  isOpera,
  isYandex,
  randomRGBA,
  truncateString,
  logOCR,
  sleep,
  serverEndpoint,
  logST,
};
