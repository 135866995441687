/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import logo from '../../assets/images/logo.png';
import constants from '../../constants';

export default function Action() {
  return (
    <section className="ui-section-close">
      <div className="ui-layout-container">
        <div className="ui-layout-column-6 ui-layout-column-center">
          <img src={logo} alt="Logo" height="48" width="48" className="img ui-section-close--logo" />
          <h2 className="h2">Improve your work.</h2>
          <p className="p ui-text-intro">
            Use our
            {' '}
            <b>ChatGPT Prompts</b>
            {' '}
            browser extension.
          </p>
          <div className="ui-component-cta ui-layout-flex">
            <a
              href={constants.extension.saveTo.chrome.url}
              role="link"
              aria-label="#"
              className="a ui-component-button ui-component-button-normal ui-component-button-primary"
            >
              Add to Chrome &mdash; It&rsquo;s Free
            </a>
            <p className="p ui-text-note">
              <small>Firefox and Safari soon.</small>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
