import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Button, Card } from '@material-ui/core';

// project imports
import { logout } from '../../../../services/firebaseService';

// style constant
const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.warning.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '200px',
      height: '200px',
      border: '19px solid ',
      borderColor: theme.palette.warning.main,
      borderRadius: '50%',
      top: '65px',
      right: '-150px',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '200px',
      height: '200px',
      border: '3px solid ',
      borderColor: theme.palette.warning.main,
      borderRadius: '50%',
      top: '145px',
      right: '-70px',
    },
  },
  tagLine: {
    color: theme.palette.grey[900],
    opacity: 0.6,
  },
  button: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.warning.main,
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));

// ===========================|| PROFILE MENU - UPGRADE PLAN CARD ||=========================== //

const SignOut = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Button variant="contained" className={classes.button} style={{ width: '100%' }} onClick={logout}>
        Sign out
      </Button>
    </Card>
  );
};

export default SignOut;
