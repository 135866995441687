import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from 'config';

// ===========================|| MAIN LOGO ||=========================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <h3>ChatGPT Prompts</h3>
  </ButtonBase>
);

export default LogoSection;
