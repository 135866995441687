import React from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';

//= =============================|| SAMPLE PAGE ||==============================//

const SamplePage = () => (
  <MainCard title="404!  Not Found.">
    <Typography variant="body2">
      Page, you are looking for does not exist!
      {' '}
      <a href="/">Go Home</a>
    </Typography>
  </MainCard>
);

export default SamplePage;
