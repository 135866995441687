/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import constants from '../../constants';

export default function Hero() {
  return (
    <section className="ui-section-hero">
      <div className="ui-layout-container">
        <div className="ui-layout-column-6 ui-layout-column-center">
          <h1 className="h1">ChatGPT Prompts</h1>
          <p className="p ui-text-intro">A Simple way to sync contacts between Desktop and Phone</p>
          <div className="ui-component-cta ui-layout-flex">
            <a
              href={constants.extension.saveTo.chrome.url}
              role="link"
              aria-label="#"
              className="a ui-component-button ui-component-button-normal ui-component-button-primary"
            >
              Add to Chrome &mdash; It&rsquo;s Free
            </a>
            <p className="ui-text-note">
              <small className="small">Firefox and Safari soon.</small>
            </p>
          </div>
        </div>
        <iframe
          loading="lazy"
          className="ui-section-hero--image"
          width="100%"
          height="500px"
          src={constants.extension.saveTo.embed}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </section>
  );
}
