import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

// settings routing
const Template = Loadable(lazy(() => import('views/pages/templates')));

// Writing Templates
const WritingTemplate = Loadable(lazy(() => import('views/pages/templates/writing/writing')));
// Writing Playground Templates
const WritingPlaygroundTemplate = Loadable(lazy(() => import('views/pages/templates/writing/playground')));

// validate routing
const Validate = Loadable(lazy(() => import('views/validate')));

// account routing
const Account = Loadable(lazy(() => import('views/account')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = ({ isLoggedIn, isPaid, search, query }) => {
    const useCount = useSelector((state) => state.saveTo.saveToUseCount);
    return {
        path: '/',
        element: isLoggedIn ? <MainLayout /> : <Navigate to={`/login${search === '' ? '?' : search}&redirect_to=/`} />,
        children: [
            {
                path: '/',
                element: isPaid || useCount < 10 ? <DashboardDefault /> : <Navigate to={`/account${search === '' ? '?' : search}`} />
            },
            {
                path: '/templates',
                element: isPaid || useCount < 10 ? <Template /> : <Navigate to="/account" />
            },
            {
                path: '/templates/writing',
                element: isPaid || useCount < 10 ? <WritingTemplate /> : <Navigate to="/account" />
            },
            {
                path: '/templates/writing/playground',
                element: isPaid || useCount < 10 ? <WritingPlaygroundTemplate /> : <Navigate to="/account" />
            },
            {
                path: '/validate',
                element: isLoggedIn ? <Validate /> : <Navigate to="/login" />
            },
            {
                path: '/account',
                element: <Account />
            }
        ]
    };
};

export default MainRoutes;
