import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import saveToReducer from './saveToReducer';
import authReducer from './authReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    customization: customizationReducer,
    saveTo: saveToReducer,
    auth: authReducer
});

export default reducer;
