import * as actionTypes from './actions';

export const initialState = {
    initial: true,
    loggedIn: false,
    isLoading: false,
    data: null,
    error: null
};

// ===========================|| SAVETO REDUCER ||=========================== //

const saveToReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return {
                ...state,
                initial: false,
                isLoading: true
            };
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loggedIn: true,
                data: action.data,
                error: null,
                initial: false
            };
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                loggedIn: false,
                initial: false
            };
        case actionTypes.AUTH_CLEAR:
            return initialState;
        default:
            return state;
    }
};

export default saveToReducer;
