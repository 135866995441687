import { useContext, useMemo } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import queryString from 'query-string';

// project
import { AuthContext } from '../context/AuthContext';

// routes
import MainRoutes from './MainRoutes';
import LegalRoutes from './legalRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import landingRoute from './landingRoute';
import Route404 from './404';

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
  const { search } = useLocation();
  const query = useMemo(() => queryString.parse(search), [search]);
  const { currentUser } = useContext(AuthContext);
  const isLoggedIn = !!currentUser;
  const isPaid = currentUser && currentUser.paid;
  return useRoutes([
    MainRoutes({
      isLoggedIn, isPaid, query, search,
    }),
    AuthenticationRoutes({
      isLoggedIn, isPaid, query, search,
    }),
    LegalRoutes,
    landingRoute,
    Route404,
  ]);
}
