import { getBrowserLangId } from './helper';

// eslint-disable-next-line import/prefer-default-export
export const initialSchema = (userId) => ({
    id: userId,
    isPaid: false,
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    locale: getBrowserLangId(),
    openAIKey: null,
    useCount: 0,
    prompts: []
});
