// assets
import { IconPhoto, IconManualGearbox, IconInfoSquare } from '@tabler/icons';

// constant
const icons = {
  IconPhoto,
  IconManualGearbox,
  IconInfoSquare,
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
  id: 'dashboard',
  title: 'Pages',
  type: 'group',
  children: [
    {
      id: 'sc',
      title: 'My Prompts',
      type: 'item',
      url: '/',
      icon: icons.IconPhoto,
      breadcrumbs: false,
    },
    {
      id: 'st',
      title: 'Template',
      type: 'item',
      url: 'templates',
      icon: icons.IconManualGearbox,
      breadcrumbs: false,
    },
    {
      id: 'gs',
      title: 'Getting Started',
      type: 'item',
      url: '/landing',
      icon: icons.IconInfoSquare,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
