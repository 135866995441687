import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import { LanguageProvider } from './context/LanguageContext';
import { AuthProvider } from './context/AuthContext';
import NavigationScroll from './layout/NavigationScroll';
// ===========================|| APP ||=========================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <AuthProvider>
        <LanguageProvider>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
        </LanguageProvider>
      </AuthProvider>
    </StyledEngineProvider>
  );
};

export default App;
